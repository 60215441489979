import { Text, Flex, Image, Box } from "@chakra-ui/react";
import * as React from "react";
import logoSvg from "./logo.svg";

export default function Logo(): React.ReactElement {
  return (
    <Flex alignItems={"center"}>
      <Box w={"70px"}>
        <Image src={logoSvg} alt={""} />
      </Box>
      <Text fontSize={"lg"}>MailPencil</Text>
    </Flex>
  );
}
