import * as React from "react";
import { Helmet } from "react-helmet";

import { Box, Heading, Container, Text, Stack, Link } from "@chakra-ui/react";
import NavBar from "../components/NavBar";
import NewsletterForm from "../components/NewsletterForm";
import Features from "../components/Features";

const IndexPage = () => (
  <>
    <Helmet>
      <title>Drag-and-drop Amazon SES email template editor - MailPencil</title>
      <link rel="canonical" href="https://mailpencil.app" />
    </Helmet>

    <Box minH={"100vh"}>
      <NavBar />
      <Container maxW={"3xl"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pt={{ base: 20, md: 36 }}
          pb={{ base: 10, md: 20 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
            color={"black"}
          >
            Let your marketing team <br />
            <Text as={"span"} color={"pink.500"}>
              own email templates
            </Text>
          </Heading>
          <Text color={"gray.900"} fontSize={{ base: "md", md: "xl" }}>
            MailPencil enables your marketing team to create and update
            transactional{" "}
            <Text fontWeight={"bold"} as={"strong"}>
              Amazon SES email templates
            </Text>{" "}
            without having to book engineers&apos; time. Keep your comms up to
            date with a{" "}
            <Text fontWeight={"bold"} as={"strong"}>
              simple drag-and-drop editor
            </Text>
            !
          </Text>
          <Stack
            direction={"column"}
            spacing={3}
            align={"center"}
            alignSelf={"center"}
            position={"relative"}
          >
            <NewsletterForm />
          </Stack>
        </Stack>
      </Container>
      <Container maxW={"6xl"}>
        <Features />
      </Container>
    </Box>
    <Box backgroundColor={"cyan.900"} marginTop={100}>
      <Container maxW={"8xl"} as={"footer"} p={4}>
        <Text fontSize={"sm"} align={"right"} color={"white"}>
          Copyright &copy; 2021 MailPencil
          <br />
          <br />
          Emoji icons by{" "}
          <Link href={"https://openmoji.org/"} rel={"nofollow"}>
            OpenMoji
          </Link>
          <br />
          Amazon Web Services, Amazon SES are trademarks of Amazon.com, Inc. or
          its affiliates in the United States and/or other countries.
        </Text>
      </Container>
    </Box>
  </>
);

export default IndexPage;
