import * as React from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Link, Heading
} from "@chakra-ui/react";
// import { FcAssistant, FcDonate, FcInTransit } from "react-icons/fc";
import { Icon as IconifyIcon } from "@iconify/react";
import heavyDollarSignIcon from "@iconify/icons-openmoji/heavy-dollar-sign";
import moveIcon from "@iconify/icons-openmoji/move";
import bustsInSilhouette from "@iconify/icons-openmoji/busts-in-silhouette";

const MoneyIcon = () => (
  <IconifyIcon width={60} height={60} icon={heavyDollarSignIcon} />
);

const DragAndDropIcon = () => (
  <IconifyIcon width={50} height={50} icon={moveIcon} />
);

const PeopleIcon = () => (
  <IconifyIcon width={40} height={40} icon={bustsInSilhouette} />
);

interface FeatureProps {
  title: string;
  children: React.ReactElement;
  icon: React.ReactElement;
}

const Feature = ({ title, children, icon }: FeatureProps) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text as={"h3"} fontWeight={600}>
        {title}
      </Text>
      {children}
    </Stack>
  );
};

export default function Features() {
  return (
    <Box>
      <Heading
        as={"h2"}
        fontSize={{ base: "xl", sm: "2xl" }}
        textAlign={"center"}
        mb={5}
      >
        Why MailPencil?
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          icon={<Icon as={DragAndDropIcon} />}
          title={"Drag-and-drop editor"}
        >
          <Text color={"gray.900"}>
            No more messing with email HTML! We construct cross-compatible
            markup from the visual editor using{" "}
            <Link rel={"nofollow"} href={"https://mjml.io/"}>
              MJML
            </Link>{" "}
            framework.
          </Text>
        </Feature>
        <Feature icon={<Icon as={MoneyIcon} />} title={"Transparent pricing"}>
          <Text color={"gray.900"}>
            We charge you a fixed monthly fee. No surprise bills on email-heavy
            months!
          </Text>
        </Feature>
        <Feature icon={<Icon as={PeopleIcon} />} title={"Collaborative"}>
          <Text color={"gray.900"}>
            Invite your design and copy teams and brainstorm the copy and layout together.
          </Text>
        </Feature>
      </SimpleGrid>
    </Box>
  );
}
