import * as React from "react";

import {
  Box,
  Stack,
  FormControl,
  Input,
  Button,
  Heading,
  Text,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import useNewsletterForm from "../../hooks/useNewsletterForm";

export default function NewsletterForm() {
  const {
    status,
    email,
    name,
    handleSubmit,
    handleEmailChange,
    handleNameChange,
  } = useNewsletterForm();

  return (
    <Box>
      <Heading
        as={"h2"}
        fontSize={{ base: "xl", sm: "2xl" }}
        textAlign={"center"}
        mb={5}
      >
        Join the waitlist
      </Heading>
      <Text color={"gray.900"} mb={3}>
        Get beta access to MailPencil alongside with occasional progress
        updates.
      </Text>
      <Stack
        direction={{ base: "column", md: "row" }}
        as={"form"}
        spacing={"12px"}
        onSubmit={handleSubmit}
      >
        <FormControl>
          <Input
            variant={"solid"}
            borderWidth={0}
            color={"gray.800"}
            _placeholder={{
              color: "gray.400",
            }}
            borderColor={"gray.900"}
            id={"first_name"}
            name={"first_name"}
            type={"text"}
            required
            placeholder={"Your name"}
            aria-label={"Your name"}
            value={name}
            disabled={status !== null}
            onChange={handleNameChange}
          />
        </FormControl>
        <FormControl>
          <Input
            variant={"solid"}
            borderWidth={0}
            color={"gray.800"}
            _placeholder={{
              color: "gray.400",
            }}
            borderColor={"gray.900"}
            id={"email_address"}
            name={"email_address"}
            type={"email"}
            required
            placeholder={"Your email"}
            aria-label={"Your email"}
            value={email}
            disabled={status !== null}
            onChange={handleEmailChange}
          />
        </FormControl>
        <FormControl w={{ base: "100%", md: "40%" }}>
          <Button
            colorScheme={status === "SUCCESS" ? "green" : "pink"}
            isLoading={status === "SUBMITTING"}
            w="100%"
            type={status === "SUCCESS" ? "button" : "submit"}
          >
            {status === "SUCCESS" ? <CheckIcon /> : "Subscribe"}
          </Button>
        </FormControl>
      </Stack>
      <Text
        mt={2}
        textAlign={"center"}
        color={status === "ERROR" ? "red.500" : "gray.500"}
      >
        {status === "ERROR" &&
          "Oh no, an error occurred! Please try again later."}
      </Text>
    </Box>
  );
}
