import * as React from "react";

type Status = null | "SUCCESS" | "SUBMITTING" | "ERROR";

export default function useNewsletterForm(): {
  status: Status;
  email: string;
  name: string;
  handleSubmit: (e: React.FormEvent) => void;
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} {
  const [status, setStatus] = React.useState<Status>(null);
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");

  const FORM_URL = `https://app.convertkit.com/forms/2339058/subscriptions`;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    const data = new FormData(target);
    try {
      setStatus("SUBMITTING");
      const response = await fetch(FORM_URL, {
        method: "post",
        body: data,
        headers: {
          accept: "application/json",
        },
      });
      setEmail("");
      const json = await response.json();
      if (json.status === "success") {
        setStatus("SUCCESS");
        return;
      }
    } catch (err) {
      setStatus("ERROR");
      console.log(err);
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setName(value);
  };

  return {
    status,
    email,
    name,
    handleSubmit,
    handleEmailChange,
    handleNameChange,
  };
}
